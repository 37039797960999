import { Loader } from '../../../../components/Loader/Loader';
import * as S from './Loading.styled';


const Loading = () => {
  return (
    <S.LoadingWrapper>
      <Loader />
    </S.LoadingWrapper>
    
  )
}

export default Loading;