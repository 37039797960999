const fontFamily = {
  primaryFont: 'Plus Jakarta Sans',
  secondaryFont: 'Inter',
};

const fontStyles = {
  h1: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '40px',
    lineHeight: '45px',
    letterSpacing: '0.01em',
  },
  h2: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '32px',
    lineHeight: '44px',
    letterSpacing: '0.01em',
  },
  h3: {
    fontfamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '30px',
    letterSpacing: '0.01em',
  },
  h4: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '25px',
    letterSpacing: '0.01em',
  },
  body: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: '500',
  },

  bodyBold: {
    fontWeight: '700',
  },
  bodyLarge: {
    fontFamily: 'Inter',
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 'regular',
    letterSpacing: '0.0px',
  },
  bodySmall: {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
  },

  bodyXSmall: {
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: '500',
  },

  buttonSmall: {
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '20x',
    fontWeight: '500',
  },
};

const color = {
  bgColor: '#f6f5f2',
  navbarBgColor: '#fefdfb',
  bgPrimaryHover: 'rgb(69, 69, 69)',

  bgColor02: '#f2f2f2',
  white: '#ffffff',

  primaryDark01: '#c16616',
  primary: '#f17f1b',
  primaryDark02: '#c16616',
  primaryLight01: '#f8bf8d',
  primaryLight02: '#fbd9bb',
  primaryLight03: '#fef2e8',

  secondaryDark: '#1a1b35',
  secondary: '#212242',

  secondaryGreyDark: '#64647b',
  secondaryGrey: '#9091a1',
  secondaryGreyLight01: '#bcbdc6',
  secondaryGreyLight02: '#e9e9ec',

  secondaryGradient: 'linear-gradient(179.97deg, #212242 43.93%, #1A1B35 99.98%)',

  progressGradient: 'linear-gradient(180deg, #8CCFC8 0%, #3F837C 100%)',

  greyscale1: '#0d0d0d',
  greyscale2: '#565656',
  greyscale3: '#868686',
  greyscale4: '#cfcfcf',
  greyscale5: '#e7e7e7',

  accent101: '#5abbb1',
  accent102: '#adddd8',
  accent103: '#eff8f7',

  accent201: '#f05f60',
  accent202: '#f8afb0',
  accent203: '#feefef',

  success1: '#12805c',
  success2: '#89c0ae',
  success3: '#e7f2ef',

  warning1: '#e68619',
  warning2: '#f3c38c',
  warning3: '#fdf3e8',

  error1: '#c9252d',
  error2: '#e49296',
  error3: '#fae9ea',
  info: '#3091D7',
};

const medias = {
  large: '(max-width: 1440px)',
  mediumLarge: '(max-width: 1200px)',
  medium: '(max-width: 992px)',
  mobile: '(max-width: 768px)',
  small: '(max-width: 576px)',
};

const theme = {
  fontFamily,
  fontStyles,
  medias,
  color,
};

export default theme;
