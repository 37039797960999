import styled from 'styled-components';

export const H1 = styled('h1')`
  font-family: ${({ theme }) => theme.fontStyles.h1.fontFamily};
  font-size: ${({ theme }) => theme.fontStyles.h1.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.h1.fontWeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.h1.letterSpacing};
  line-height: ${({ theme }) => theme.fontStyles.h1.lineHeight};
`;

export const H2 = styled('h2')`
  font-family: ${({ theme }) => theme.fontStyles.h2.fontFamily};
  font-size: ${({ theme }) => theme.fontStyles.h2.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.h2.fontWeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.h2.letterSpacing};
  line-height: ${({ theme }) => theme.fontStyles.h2.lineHeight};

  @media ${({ theme }) => theme.medias.mobile} {
    font-size: ${({ theme }) => theme.fontStyles.h3.fontSize};
  }
`;

export const H3 = styled('h3')`
  font-family: ${({ theme }) => theme.fontStyles.h3.fontFamily};
  font-size: ${({ theme }) => theme.fontStyles.h3.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.h3.fontWeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.h3.letterSpacing};
  line-height: ${({ theme }) => theme.fontStyles.h3.lineHeight};
`;

export const H4Bold = styled('h4')`
  font-family: ${({ theme }) => theme.fontStyles.h4.fontFamily};
  font-size: ${({ theme }) => theme.fontStyles.h4.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.bodyBold.fontWeight};
  letter-spacing: ${({ theme }) => theme.fontStyles.h4.letterSpacing};
  line-height: ${({ theme }) => theme.fontStyles.h4.lineHeight};
  @media ${({ theme }) => theme.medias.mobile} {
    font-size: 16px;
    line-height: 25px;
  }
`;

export const Body = styled('p')`
  font-family: ${({ theme }) => theme.fontStyles.body.fontFamily};
  font-size: ${({ theme }) => theme.fontStyles.body.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.body.fontWeight};
  line-height: ${({ theme }) => theme.fontStyles.body.lineHeight};
`;

export const BodyBold = styled(Body)`
  font-weight: ${({ theme }) => theme.fontStyles.bodyBold.fontWeight};
`;

export const BodyLarge = styled('p')`
  font-family: ${({ theme }) => theme.fontStyles.bodyLarge.fontFamily};
  font-size: ${({ theme }) => theme.fontStyles.bodyLarge.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.bodyLarge.fontWeight};
  line-height: ${({ theme }) => theme.fontStyles.bodyLarge.lineHeight};
`;

export const BodySmall = styled('p')`
  font-family: ${({ theme }) => theme.fontStyles.bodySmall.fontFamily};
  font-size: ${({ theme }) => theme.fontStyles.bodySmall.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.bodySmall.fontWeight};
  line-height: ${({ theme }) => theme.fontStyles.bodySmall.lineHeight};
  color: ${({ theme }) => theme.color.greyscale3};
`;

export const BodyXSmall = styled('p')`
  font-family: ${({ theme }) => theme.fontStyles.bodyXSmall.fontFamily};
  font-size: ${({ theme }) => theme.fontStyles.bodyXSmall.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.bodyXSmall.fontWeight};
  line-height: ${({ theme }) => theme.fontStyles.bodyXSmall.lineHeight};
  color: ${({ theme }) => theme.color.secondaryGrey};
`;

export const BodyXSmallBold = styled(BodyXSmall)`
  font-weight: ${({ theme }) => theme.fontStyles.bodyBold.fontWeight};
`;

export const ButtonSmall = styled('button')`
  font-family: ${({ theme }) => theme.fontStyles.buttonSmall.fontFamily};
  font-size: ${({ theme }) => theme.fontStyles.buttonSmall.fontSize};
  font-weight: ${({ theme }) => theme.fontStyles.buttonSmall.fontWeight};
  line-height: ${({ theme }) => theme.fontStyles.buttonSmall.lineHeight};
`;
