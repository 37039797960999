import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FullscreenAchievementModalState {
  isOpened: boolean;
  text: string;
  badgeUrl?: string;
  isSuccess: boolean;
  header: string;
}

const initialState: FullscreenAchievementModalState = {
  isOpened: false,
  text: '',
  isSuccess: false,
  header: '',
};

export const fullscreenAchievementModalSlice = createSlice({
  name: 'fullscreenAchievementModal',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        text: string;
        header: string;
        badgeUrl?: string;
        isSuccess: boolean;
      }>,
    ) => {
      state.isOpened = true;
      state.isSuccess = action.payload.isSuccess;
      state.text = action.payload.text;
      state.badgeUrl = action.payload.badgeUrl;
      state.header = action.payload.header;
    },
    closeModal: (state) => {
      state.isOpened = false;
      state.isSuccess = false;
      state.text = '';
      state.badgeUrl = '';
    },
  },
});

export const { openModal, closeModal } = fullscreenAchievementModalSlice.actions;
export default fullscreenAchievementModalSlice.reducer;
