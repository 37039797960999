import { Auth0Provider } from '@auth0/auth0-react';
import { routes } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN || 'heyedu.eu.auth0.com';
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || 'nPpyumG0WaNsygR33ShWTc6zG3L8WVWa';
  const redirectUri =
    process.env.REACT_APP_AUTH0_CALLBACK_URL || window.location.origin + '/login-success';

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      audience="heyedu-sls"
      onRedirectCallback={async (a, u) => {
        if (u) {
          navigate(a?.returnTo || window.location.pathname);
        }
      }}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};
