import * as S from './styled';

interface LoaderProps {
  height?: string;
  spinnerSize?: string;
  showLoadingText?: boolean;
}
export const Loader = ({
  height = '400px',
  spinnerSize = '2rem',
  showLoadingText = false,
}: LoaderProps) => {
  let minHeight = height;
  if (showLoadingText) {
    minHeight = '39px';
  }
  return (
    <S.Loader style={{ height: height, minHeight }}>
      <div
        className="spinner-border text-primary"
        role="status"
        style={{ height: spinnerSize, width: spinnerSize }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
      {showLoadingText ? <S.LoadingText /> : <></>}
    </S.Loader>
  );
};

export const FullScreenLoader = ({ height = '400px' }: LoaderProps) => {
  return (
    <S.Wrapper>
      <S.Loader style={{ height: height }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </S.Loader>
    </S.Wrapper>
  );
};
