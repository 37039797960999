import styled from 'styled-components';
import { Modal as ModalAntd } from 'antd';

export const BasicModal = styled(ModalAntd)<{ size?: undefined | 'large' }>`
  @media ${({ theme }) => theme.medias.mediumLarge} {
    width: ${({ size }) => (size === 'large' ? '90% !important' : '520px')};
  }
  .ant-modal-content {
    width: ${({ size }) => (size === 'large' ? '848px' : '490px')};
    padding: ${({ size }) => (size === 'large' ? '42px 64px 64px 64px' : '48px')};
    background: ${({ theme }) => theme.color.bgColor};
    border: 2.5px solid #212242;
    box-shadow: 0px 15px 20px -1px ${({ theme }) => theme.color.secondary};
    border-radius: 24px;
    margin: 0 auto;
    @media ${({ theme }) => theme.medias.mediumLarge} {
      width: ${({ size }) => (size === 'large' ? '90%' : '490px')};
    }
    @media ${({ theme }) => theme.medias.mobile} {
      width: 95%;
      padding: 24px;
    }
  }
  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-mask {
    background: rgba(33, 34, 66, 0.5);
    backdrop-filter: blur(10px);
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-evenly;
  }
`;

export const ConfirmModal = styled(ModalAntd)`
  .ant-modal-wrap {
    background: rgba(33, 34, 66, 0.5);
    backdrop-filter: blur(10px);
  }
  .ant-modal-content {
    width: 360px;
    padding: 12px;
    background: ${({ theme }) => theme.color.bgColor};
    box-shadow: 0 -5px 0 ${({ theme }) => theme.color.primaryLight01};
    border-radius: 4px;
    margin: 0 auto;
    top: 170px;
    @media ${({ theme }) => theme.medias.mobile} {
      width: 90%;
      margin: 0 auto;
    }
  }

  .ant-modal-header {
    background: transparent;
    padding: 0 30px 0 0;
    border-bottom: none;
  }

  .ant-modal-close {
    top: 9px;
    .ant-modal-close-x {
      width: 36px;
      height: 24px;
      line-height: 24px;
    }
  }

  .ant-modal-body {
    padding: 0px;
  }
`;
