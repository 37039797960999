import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { SLS_API_URL, SLS_USER_ENDPOINTS } from '../../constants/apiEndpoints';

import { reset as resetRewards } from './rewardsSlice';
import { reset as resetSkills } from './skilsSlice';
import { User } from '../../types/User';
import { getSlsAxiosInstnce } from '../../hooks/useSls';
import { addSpinner, removeSpinner } from './spinnersSlice';
import { AxiosError } from 'axios';
import { GetIdTokenClaimsOptions } from '@auth0/auth0-spa-js';
import { IdToken } from '@auth0/auth0-react';

export const SELECTED_LANG_KEY = 'lang';

interface Thunk3rdArg {
  dispatch: AppDispatch;
  state: RootState;
}

type UpdateHero = {
  data: Omit<User, 'id'>;
  options: {
    spinners: string[];
  };
  getAccessTokenSilently(): Promise<string>;
};

export const updateHero = createAsyncThunk<User, UpdateHero, Thunk3rdArg>(
  'pathUser/me',
  async (payload, { dispatch, getState }) => {
    for await (const spinner of payload.options.spinners) {
      await dispatch(addSpinner(spinner));
    }

    const { user } = getState();
    const slsAxios = getSlsAxiosInstnce(payload.getAccessTokenSilently);
    const response = await slsAxios.patch<{ item: User }>(
      SLS_USER_ENDPOINTS.PATCH_USER(user?.user?.id as string),
      payload.data,
    );

    for await (const spinner of payload.options.spinners) {
      await dispatch(removeSpinner(spinner));
    }

    return response.data.item;
  },
);

type DiscordData = {
  id: string | null;
  username: string | null;
  error: string | null;
};

type UpdateUserDiscordParams = {
  id: string | null;
  username: string | null;
  getAccessTokenSilently(): Promise<string>;
};

export const updateUserDiscord = createAsyncThunk<
  DiscordData,
  UpdateUserDiscordParams,
  Thunk3rdArg
>('update/discord', async ({ id, username, getAccessTokenSilently }, { dispatch, getState }) => {
  try {
    await dispatch(addSpinner('user_settings.discord'));

    const { user } = getState();
    const slsAxios = getSlsAxiosInstnce(getAccessTokenSilently);

    if (id === null) {
      await slsAxios.patch(SLS_USER_ENDPOINTS.PATCH_USER_DISCORD(user?.user?.id as string), {
        id: null,
        username: null,
        error: null,
      });

      await dispatch(removeSpinner('user_settings.discord'));

      return {
        id: null,
        username: null,
        error: null,
      };
    }

    if (username === null) {
      const response = await slsAxios.get<{ discordUsername: string; discordId: string }>(
        SLS_USER_ENDPOINTS.GET_USER_DISCORD(user?.user?.id as string),
      );

      await slsAxios.patch(SLS_USER_ENDPOINTS.PATCH_USER_DISCORD(user?.user?.id as string), {
        id,
        username: response.data.discordUsername,
      });

      await dispatch(removeSpinner('user_settings.discord'));

      return {
        id,
        username: response.data.discordUsername,
        error: null,
      };
    }

    await slsAxios.patch(SLS_USER_ENDPOINTS.PATCH_USER_DISCORD(user?.user?.id as string), {
      id,
      username,
    });

    await dispatch(removeSpinner('user_settings.discord'));

    return {
      id,
      username,
      error: null,
    };
  } catch (e) {
    if (e instanceof AxiosError) {
      return {
        id,
        username,
        error: e?.response?.data ?? 'Something went wring',
      };
    }

    return {
      id,
      username,
      error: (e as Error)?.message ?? 'Something went wring',
    };
  }
});

export const logOut = createAsyncThunk<void, void, Thunk3rdArg>(
  'logout',
  async (_, { dispatch }) => {
    dispatch(resetRewards());
    dispatch(resetSkills());
    dispatch(reset());
  },
);

export const fetchUser = createAsyncThunk<
  User,
  {
    getAccessTokenSilently(): Promise<string>;
  },
  Thunk3rdArg
>(SLS_USER_ENDPOINTS.GET_USER, async ({ getAccessTokenSilently }, { dispatch, getState }) => {
  const { user } = getState();

  if (!user.user) {
    dispatch(showLoader());
  }
  const slsAxios = getSlsAxiosInstnce(getAccessTokenSilently);
  const responseUser = await slsAxios.get<{ item: User }>(SLS_USER_ENDPOINTS.GET_USER);

  localStorage.setItem(SELECTED_LANG_KEY, responseUser.data.item.metadata.selectedLanguage ?? 'en');

  return responseUser.data.item;
});

export const loginUser = createAsyncThunk<
  { user: User | null },
  {
    getAccessTokenSilently(): Promise<string>;
    getIdTokenClaims(options?: GetIdTokenClaimsOptions): Promise<IdToken | undefined>;
  },
  Thunk3rdArg
>(
  `${SLS_USER_ENDPOINTS.GET_USER}/login`,
  async ({ getAccessTokenSilently, getIdTokenClaims }, { dispatch, getState }) => {
    const { user } = getState();

    if (!user.user) {
      dispatch(showLoader());
    }
    const token = await getAccessTokenSilently();
    const idToken = await getIdTokenClaims({});

    //weird hack to follow cors
    //and be able to go with custom headers
    //... on localhost...
    const url = `${SLS_API_URL}${SLS_USER_ENDPOINTS.GET_USER}`;
    const request = new Request(url, {
      method: 'GET',
      headers: {
        'x-token-id': idToken?.__raw ?? '',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    });
    const responseUser = await fetch(request);
    if (responseUser.status === 401) {
      return {
        user: null,
        error: 'Unauthorized',
      };
    }
    const body = await responseUser.json();

    localStorage.setItem(SELECTED_LANG_KEY, body.item.metadata.selectedLanguage ?? 'en');
    return {
      user: body.item,
    };
  },
);

export interface UserState {
  user: User | null;
  isLoading: boolean;
  hero: {
    discord: { id: string | null; username: string | null; error: string | null };
  };
}

const initialState = (): UserState => {
  const user: User | null = null;
  return {
    user,
    isLoading: false,
    hero: {
      discord: {
        id: null,
        username: null,
        error: null,
      },
    },
  };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState(),
  reducers: {
    reset: (state) => {
      state.user = null;
    },
    showLoader: (state) => {
      state.isLoading = true;
    },
    hideLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUser.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.hero.discord = {
        id: action.payload.discordId ?? null,
        username: action.payload.discordUsername ?? null,
        error: null,
      };
    });

    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
    });

    builder.addCase(updateHero.fulfilled, (state, action) => {
      if (state.user) {
        state.user = action.payload;
      }
    });

    builder.addCase(updateUserDiscord.fulfilled, (state, action) => {
      state.hero.discord = action.payload;
    });
  },
});

export const { reset, showLoader, hideLoader } = userSlice.actions;

export default userSlice.reducer;
