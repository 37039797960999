import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {} as Record<string, boolean>;

const spinnersSlice = createSlice({
  name: 'spinners',
  initialState,
  reducers: {
    addSpinner(state, action: PayloadAction<string>) {
      state[action.payload] = true;
    },
    removeSpinner(state, action: PayloadAction<string>) {
      delete state[action.payload];
    },
  },
});

export const { addSpinner, removeSpinner } = spinnersSlice.actions;
export default spinnersSlice.reducer;
