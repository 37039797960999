import { UploadFileType } from '../hooks/useSls';

export const MAINNET_ALCHEMY_API_KEY = 'umnx9mAEzPgs3UW4jyDOWDnPVkw2u8RN';
export const MAINNET_ALCHEMY_API_URL = `https://eth-mainnet.g.alchemy.com/v2/${MAINNET_ALCHEMY_API_KEY}`;

export const OPTIMISM_ALCHEMY_API_KEY = 'MdoKuQrqPMOe4FCDMh90OBKSL1AdsdBm';
export const OPTIMISM_ALCHEMY_APU_URL = `https://opt-mainnet.g.alchemy.com/v2/${OPTIMISM_ALCHEMY_API_KEY}`;

const SLS_API_URI =
  process.env.REACT_APP_SLS_BACKEND_URL ||
  // 'https://p0yc2rl57j.execute-api.eu-west-1.amazonaws.com/prod/' ||
  'https://ld5lcri7mh.execute-api.eu-west-1.amazonaws.com/dev/' ||
  'http://localhost:3005/dev/';

export const logoutUrl =
  process.env.REACT_APP_AUTH0_CALLBACK_LOGOUTURL || window.location.origin + '/login';

export const SLS_API_URL = `${SLS_API_URI}`;
export const SLS_USER_ENDPOINTS = {
  GET_USER: `user`,
  POST_USER: 'user',
  PATCH_USER: (id: string) => `user/${id}`,
  PATCH_USER_DISCORD: (id: string) => `user/${id}/discord`,
  GET_USER_DISCORD: (id: string) => `user/${id}/discord`,
  GET_HONEYPOT_USERS: 'get-honeypot-users',
  GET_BADGES: 'user/badges',
  GET_QUESTS: 'user/quests',
  GET_REWARDS: 'user/rewards',
  GET_WORLDS: `user/worlds`,
  GET_PATHS: (worldName: string) => `user/path/${worldName}`,
  GET_EDU_BALANCE: `user/edu-balance`,
  GET_PUBLIC_PROFILE_SETTINGS: (id: string) => `user/${id}/publicProfileSettings`,
  PUT_PUBLIC_PROFILE_SETTINGS: (id: string) => `user/${id}/publicProfileSettings`,
  VALIDATE_ANSWER_REQUEST: (userId: string, taskHeid: string) =>
    `answer/isValid?userId=${userId}&taskHeid=${encodeURIComponent(taskHeid)}`,
  UPLOAD_FILE: (uploadFileType: UploadFileType): string => `utils/upload-file/${uploadFileType}`,
  REMOVE_FILE: (uploadFileType: UploadFileType): string => `utils/delete-file/${uploadFileType}`,
  GET_SIGNED_DOWNLOAD_URL: (url: string): string =>
    `utils/get-signed-download-url?url=${encodeURIComponent(url)}`,
  SAVE_ANSWER: (userId: string, taskHeid: string): string =>
    `answer/?userId=${userId}&taskHeid=${encodeURIComponent(taskHeid)}`,
  GET_ANSWER_TO_CHECK: 'answer/next',
  GET_ANSWER_COUNT: 'answer/count',
  POST_ANSWER_FEEDBACK: (answerId: string): string => `answer/${answerId}/feedback`,
  PUT_MARK_AS_ADMIN_REQUIRED: (answerId: string): string => `answer/${answerId}/admin-required`,
  MODIFY_ACTIVE_PATH: (userId: string): string => `user/${userId}/active-path`,
  MODIFY_WORLD: (userId: string): string => `user/${userId}/world`,
};

export const SLS_GAME_ITEM_ENDPOINTS = {
  GET_PRICE: (itemId: string) => `game-item/${itemId}/price`,
  POST_BUY_ITEM: (itemId: string) => `game-item/${itemId}/buy`,
  POST_USE_ITEM: (itemId: string) => `game-item/${itemId}/use`,
};

export const WEB3 = {
  GETSIGNATURE: 'web3/get-signature',
};

export const WORLDS = {
  GETWORLDS: 'worlds',
};
