import { RootState } from '../store';

export const selectUser = ({ user }: RootState) => user.user;

export const selectUserGamedata = ({ user }: RootState) =>
  user.user?.gameData ?? {
    exp: undefined,
    heroLevel: undefined,
    openedTasks: undefined,
    expToLevelUp: undefined,
    activePaths: [],
    skills: [],
    worlds: [],
    availableWorlds: [],
    availablePaths: [],
  };
export const selectUserIsLoading = ({ user }: RootState) => user.isLoading;
export const selectUserBadges = ({ user }: RootState) => user.user?.badges ?? [];
export const selectUserMetadata = ({ user }: RootState) =>
  user.user?.metadata ?? {
    city: null,
    birthday: null,
    expirience: null,
    sentiments: null,
    dilemmas: null,
    gallup: [],
    communities: [],
    prefferedLearning: null,
    contentLanguagePreference: null,
    prefferedEmailHours: [],
    assistantName: null,
    selectedLanguage: null,
  };
export const selectUserDiscord = ({ user }: RootState) => user.hero.discord;
