import styled from "styled-components";
import { BodySmall, H4Bold } from "../Typography/Typography.styled";

export const StyledH4Bold = styled(H4Bold)`
  margin: 20px 0 32px 0;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.secondary};
  @media ${({ theme }) => theme.medias.medium} {
    margin: 10px 0 24px 0;
  }
`;

export const StyledBodySmall = styled(BodySmall)`
  color: ${({ theme }) => theme.color.greyscale2};
  font-size: 15px;
`;

export const StyledLinkSmall = styled(BodySmall)`
  font-size: 15px;
`