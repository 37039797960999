import styled, { keyframes } from 'styled-components';

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  flex-direction: column;
`;

const LoadingTextKeyframe = keyframes`
  0% {
    content: "Loading"
  }
  25% {
    content: "Loading."
  }
  50% {
    content: "Loading.."
  }
  75%{
  content: "Loading..."
  }
`;
export const LoadingText = styled.div`
  &:after {
    content: 'Loading';
    animation-name: ${LoadingTextKeyframe};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
