import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import fullscreenModalReducer from './slices/fullscreenModalSlice';
import skillsReducer from './slices/skilsSlice';
import rewardsReducer from './slices/rewardsSlice';
import spinnersReducer from './slices/spinnersSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    fullscreenAchievementModal: fullscreenModalReducer,
    skills: skillsReducer,
    rewards: rewardsReducer,
    spinners: spinnersReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
