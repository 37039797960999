import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { FullScreenLoader } from './components/Loader/Loader';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { routes } from './constants/routes';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { en, pl } from 'make-plural/plurals';
import { messages as messagesEn } from './locales/en/messages';
import { messages as messagesPl } from './locales/pl/messages';
import { fetchUser, hideLoader, SELECTED_LANG_KEY } from './redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserMetadata } from './redux/selectors/userSelector';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginSuccess } from './pages/LoginSuccess/LoginSuccess';
import { AppDispatch } from './redux/store';
import { useUser } from './hooks/useUser';
import { OnboardingModal, TSupportedMissingData } from './components/Modal/OnboardingModal';

i18n.load('en', messagesEn);
i18n.load('pl', messagesPl);
const lang = localStorage.getItem(SELECTED_LANG_KEY);
i18n.activate(lang ?? 'pl');
i18n.loadLocaleData('en', { plurals: en });
i18n.loadLocaleData('pl', { plurals: pl });

const PortalHome = lazy(() => import('./pages/PortalHome/PortalHome'));
const Login = lazy(() => import('./pages/Login/Login'));
const AuthDiscord = lazy(() => import('./pages/Auth/Discord'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const Layout = lazy(() => import('./template/Layout/Layout'));
const Worlds = lazy(() => import('./pages/Worlds/Worlds'));
const WorldDetails = lazy(() => import('./pages/WorldDetails/WorldDetails'));
const UserInfoSettings = lazy(() => import('./pages/Settings/UserInfoSettings/UserInfoSettings'));
const MyEdu = lazy(() => import('./pages/MyEdu/MyEdu'));
const MyBadges = lazy(() => import('./pages/MyBadges/MyBadges'));
const WorldBadges = lazy(() => import('./pages/MyBadges/components/WorldBadges/WorldBadges'));
const WorldsLayout = lazy(() => import('./template/WorldsLayout/WorldsLayout'));
const WorldLayout = lazy(() => import('./template/WorldLayout/WorldLayout'));
const WorldMap = lazy(() => import('./pages/WorldMap/WorldMap'));
const SubscriptionSettings = lazy(
  () => import('./pages/Settings/SubscriptionSettings/SubscriptionSettings'),
);
const TaskAnswer = lazy(() => import('./pages/TaskAnswer/TaskAnswer'));
const TaskAnswerSubmitted = lazy(() => import('./pages/TaskAnswerSubmitted/TaskAnswerSubmitted'));
const CheckAnswer = lazy(() => import('./pages/CheckAnswer/CheckAnswer'));
const Chat = lazy(() => import('./pages/Chat/Chat'));

const App = () => {
  const location = useLocation();
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const userMetadata = useSelector(selectUserMetadata);
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useUser();
  const [showOnboardingModal, setShouldShowOnboardingModal] = useState<boolean>(false);
  const [onboardingMissingData, setOnboardingMissingData] = useState<TSupportedMissingData[]>([]);

  useEffect(() => {
    if (userMetadata?.selectedLanguage) {
      i18n.activate(userMetadata.selectedLanguage);
    }
  }, [userMetadata]);

  useEffect(() => {
    setOnboardingMissingData([]);
    if (!user) {
      return;
    }

    if (location.pathname === routes.portal.settings.userDetails) {
      setShouldShowOnboardingModal(false);
      return;
    }

    if (!user.systemData.name) {
      setOnboardingMissingData([...onboardingMissingData, 'name']);
      setShouldShowOnboardingModal(true);
    }

    if (!user.ethWallet) {
      setOnboardingMissingData([...onboardingMissingData, 'ethWallet']);
      setShouldShowOnboardingModal(true);
    }

    if (!user.discordId) {
      setOnboardingMissingData([...onboardingMissingData, 'discordId']);
      setShouldShowOnboardingModal(true);
    }
  }, [user, location]);

  useEffect(() => {
    const f = async () => {
      return dispatch(fetchUser({ getAccessTokenSilently }));
    };
    if (!user) {
      f().then(() => {
        dispatch(hideLoader());
      });
    }
  }, []);

  return (
    <I18nProvider i18n={i18n}>
      <Suspense fallback={<FullScreenLoader />}>
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route path={routes.login} element={<Login loading={isLoading} />} />
            <Route path={routes.loginSuccess} element={<LoginSuccess />} />
            <Route path={routes.taskAnswer.self} element={<TaskAnswer />} />
            <Route path={routes.taskAnswer.submitted} element={<TaskAnswerSubmitted />} />
            <Route path={routes.portal.self} element={<PrivateRoute Component={Layout} />}>
              <Route path={routes.portal.auth.discord} element={<AuthDiscord />} />
              <Route index element={<PortalHome />} />
              <Route path={routes.portal.settings.self} element={<Settings />}>
                <Route path={routes.portal.settings.userDetails} element={<UserInfoSettings />} />
                <Route
                  path={routes.portal.settings.subscription}
                  element={<SubscriptionSettings />}
                />
              </Route>
              <Route path={routes.portal.myEdu} element={<MyEdu />} />
              <Route path={routes.portal.myBadges.self} element={<MyBadges />}>
                <Route path={routes.portal.myBadges.worldBadges} element={<WorldBadges />} />
              </Route>
              <Route path={routes.portal.worlds.self} element={<WorldsLayout />}>
                <Route path={routes.portal.worlds.self} element={<Worlds />} />
                <Route path={routes.portal.worlds.world.self} element={<WorldLayout />}>
                  <Route path={routes.portal.worlds.world.self} element={<WorldMap />} />
                  <Route path={routes.portal.worlds.world.details} element={<WorldDetails />} />
                </Route>
              </Route>
              <Route path={routes.portal.checkAnswer.self} element={<CheckAnswer />} />
              <Route path={routes.portal.chat.self} element={<Chat />} />
            </Route>
            <Route path="*" element={<Navigate replace to={routes.portal.worlds.self} />} />
          </Routes>
          <OnboardingModal
            onCancel={() => {}}
            isShown={showOnboardingModal}
            missingData={onboardingMissingData}
          />
        </AnimatePresence>
      </Suspense>
    </I18nProvider>
  );
};

export default App;
