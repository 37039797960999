import { include } from 'named-urls';

export const routes = {
  login: '/login',
  loginSuccess: '/login-success',
  taskAnswer: include('/answer/', {
    self: '',
    submitted: 'submitted',
  }),

  portal: include('/portal/', {
    self: '',
    auth: include('auth/', {
      self: '',
      discord: 'discord',
    }),
    worlds: include('worlds/', {
      self: '',
      world: include(':worldName/', {
        self: '',
        details: 'details',
      }),
    }),
    quests: 'quests/',
    rewards: 'rewards/',
    myEdu: 'myEdu/',
    myNft: include('myNft/', {
      self: '',
      genesis: 'genesis',
      founders: 'founders',
    }),
    founders: 'founders/',
    genesis: 'genesis/',
    settings: include('settings/', {
      self: '',
      userDetails: 'userDetails',
      publicProfile: 'publicProfile',
      subscription: 'subscription',
    }),
    myBadges: include('myBadges/', {
      self: '',
      worldBadges: ':worldName/',
    }),
    checkAnswer: include('answers/', {
      self: '',
    }),
    chat: include('chat/', {
      self: '',
    }),
  }),

  admin: include('/admin/', {
    self: '',
    users: include('users/', {
      self: '',
      user: ':uid/',
    }),
  }),

  notFoundPage: '/404',
};
