import { useFetchUser } from '../../hooks/useFetchUser';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useEffect, useState } from 'react';
import Loading from '../Settings/common/Loading/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { loginUser, logOut } from '../../redux/slices/userSlice';
import { AppDispatch } from '../../redux/store';
import { logoutUrl } from '../../constants/apiEndpoints';

export const LoginSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated, logout } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsLoading(true);
      return;
    }
    const f = async () => {
      await dispatch(loginUser({ getAccessTokenSilently, getIdTokenClaims }));
    };
    f()
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        dispatch(logOut());
        logout({
          returnTo: logoutUrl,
        });
        navigate(routes.login);
      });
  }, [isAuthenticated, dispatch, getAccessTokenSilently]);

  if (isLoading) {
    return <Loading />;
  } else {
    navigate(routes.portal.worlds.self);
  }

  return <></>;
};
