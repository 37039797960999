import axios, { AxiosInstance } from 'axios';
import {
  SLS_API_URL,
  SLS_GAME_ITEM_ENDPOINTS,
  SLS_USER_ENDPOINTS,
  WEB3,
  WORLDS,
} from '../constants/apiEndpoints';
import { PublicProfileSettings, User } from '../types/User';
import { World } from '../types/World';
import { Path } from '../types/Path';
import { PathLevel } from '../types/PathLevel';
import { BigNumber } from 'ethers';
import { useAuth0 } from '@auth0/auth0-react';

export function getSlsAxiosInstnce(
  getAccessTokenSilently: () => Promise<string>,
  requireAuthorization: boolean = true,
): AxiosInstance {
  const instance = axios.create({
    baseURL: `${SLS_API_URL}`,
  });

  instance.interceptors.request.use(
    async (config) => {
      if (!config.headers) {
        config.headers = {};
      }
      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }

      if (requireAuthorization) {
        const token = await getAccessTokenSilently();
        if (!token) {
          throw new axios.Cancel('Token missing');
        }
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return instance;
}

function useSls(requireAuthorization: boolean = true) {
  const { getAccessTokenSilently } = useAuth0();
  const slsAxios = getSlsAxiosInstnce(getAccessTokenSilently, requireAuthorization);

  const getUserBadges = async () => {
    const response = await slsAxios.get(SLS_USER_ENDPOINTS.GET_BADGES);
    return response?.data;
  };

  const getWorlds = async (): Promise<{ worlds: World[] }> => {
    const response = await slsAxios.get<{ worlds: World[] }>(SLS_USER_ENDPOINTS.GET_WORLDS);

    return response?.data;
  };

  const getPaths = async (worldName: string): Promise<GetPathsPathModel[]> => {
    const response = await slsAxios.get<{ paths: GetPathsPathModel[] }>(
      SLS_USER_ENDPOINTS.GET_PATHS(worldName),
    );

    return response?.data.paths;
  };

  const getSignature = async () => {
    const response = await slsAxios.post<{
      signature: string;
      eduEarned: string;
    }>(WEB3.GETSIGNATURE);

    return response?.data;
  };

  const getEduBalance = async () => {
    const response = await slsAxios.get<{ eduBalance: BigNumber }>(
      SLS_USER_ENDPOINTS.GET_EDU_BALANCE,
    );
    return response.data;
  };

  type getAllWorldsModel = {
    worldName: string;
    displayName: string;
  };
  const getAllWorlds = async () => {
    const response = await slsAxios.get<{ items: getAllWorldsModel[] }>(WORLDS.GETWORLDS);
    return response.data.items;
  };

  const getPublicProfileSettings = async (userId: string) => {
    const response = await slsAxios.get<{ settings: PublicProfileSettings }>(
      SLS_USER_ENDPOINTS.GET_PUBLIC_PROFILE_SETTINGS(userId),
    );
    return response.data;
  };

  const setPublicProfileSettings = async (
    userId: string,
    publicProfileSettings: PublicProfileSettings,
  ) => {
    const response = await slsAxios.put<{ item: PublicProfileSettings }>(
      SLS_USER_ENDPOINTS.PUT_PUBLIC_PROFILE_SETTINGS(userId),
      {
        ...publicProfileSettings,
      },
    );
    return response.data;
  };

  const validateAnswerRequest = async (userId: string, taskHeid: string) => {
    const response = await slsAxios.get<{
      status: 'notValid' | 'notAnswered' | 'answered' | 'checkedAndApproved' | 'checkedAndRejected';
      incorrectAnswer: string | null;
      feedback: string | null;
      taskName: string | null;
    }>(SLS_USER_ENDPOINTS.VALIDATE_ANSWER_REQUEST(userId, taskHeid));
    return response.data;
  };

  const uploadFile = async (file: any, uploadFileType: UploadFileType, params: any = {}) => {
    const response = await slsAxios.post<{ uploadFilesIds: string[] }>(
      SLS_USER_ENDPOINTS.UPLOAD_FILE(uploadFileType),
      {
        file,
        ...params,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  };
  const removeFile = async (fileId: string, uploadFileType: UploadFileType, params: any = {}) => {
    const resposne = await slsAxios.post(SLS_USER_ENDPOINTS.REMOVE_FILE(uploadFileType), {
      fileId,
      ...params,
    });
  };

  const getSignedDownloadUrl = async (url: string): Promise<string> => {
    const response = await slsAxios.get<{ signedUrl: string }>(
      SLS_USER_ENDPOINTS.GET_SIGNED_DOWNLOAD_URL(url),
    );
    return response.data.signedUrl;
  };

  const saveAnswer = async (userId: string, taskHeid: string, answer: string, files: string[]) => {
    const response = await slsAxios.post(SLS_USER_ENDPOINTS.SAVE_ANSWER(userId, taskHeid), {
      answer,
      files,
    });
  };

  const getAnswerToCheck = async () => {
    try {
      const response = await slsAxios.get<{
        task: string;
        answer: string;
        answerKey: string;
        answerId: string;
        title: string;
        links: string[];
        student: {
          name: string;
          id: string;
        };
      }>(SLS_USER_ENDPOINTS.GET_ANSWER_TO_CHECK);

      return response.data;
    } catch (e) {
      return null;
    }
  };

  interface ModifyPathInput {
    userId: string;
    fullPathLevelId: string;
    action: 'addPathLevel';
    message?: string;
  }
  const modifyPath = async ({
    userId,
    fullPathLevelId,
    action,
    message,
  }: ModifyPathInput): Promise<boolean> => {
    try {
      const response = await slsAxios.put(SLS_USER_ENDPOINTS.MODIFY_ACTIVE_PATH(userId), {
        fullPathLevelId,
        action,
        message,
        useTaskAutomation: true,
      });

      return response.status === 200;
    } catch (e) {
      return false;
    }
  };

  interface ModifyWorldInput {
    userId: string;
    worldName: string;
    action: 'addWorld';
  }
  const modifyWorld = async ({ userId, worldName, action }: ModifyWorldInput): Promise<boolean> => {
    try {
      const response = await slsAxios.put(SLS_USER_ENDPOINTS.MODIFY_WORLD(userId), {
        worldName,
        action,
      });

      return response?.status === 200;
    } catch (e) {
      return false;
    }
  };

  const postAnswerFeedback = async (
    answerId: string,
    { action, feedback }: { action: 'approve' | 'reject'; feedback: string },
  ) => {
    try {
      const response = await slsAxios.post(SLS_USER_ENDPOINTS.POST_ANSWER_FEEDBACK(answerId), {
        action,
        feedback,
      });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  };

  const getWaitingAnswerCount = async (): Promise<string> => {
    try {
      const response = await slsAxios.get<{ count: string }>(SLS_USER_ENDPOINTS.GET_ANSWER_COUNT);
      return response.data.count;
    } catch (e) {
      console.error(e);
      return '0';
    }
  };

  const markAnswerAsAdminRequired = async (answerId: string) => {
    try {
      await slsAxios.put(SLS_USER_ENDPOINTS.PUT_MARK_AS_ADMIN_REQUIRED(answerId));
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const getPrice = async (itemId: string): Promise<number> => {
    try {
      const response = await slsAxios.get<{ price: number }>(
        SLS_GAME_ITEM_ENDPOINTS.GET_PRICE(itemId),
      );
      return response.data.price;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const buyItem = async (itemId: string) => {
    try {
      const response = await slsAxios.post(SLS_GAME_ITEM_ENDPOINTS.POST_BUY_ITEM(itemId));
      return response.status === 200;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  const useItem = async (itemId: string, additionalData: any) => {
    try {
      const response = await slsAxios.post(
        SLS_GAME_ITEM_ENDPOINTS.POST_USE_ITEM(itemId),
        additionalData,
      );
      return response.status === 200;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  return {
    getUserBadges,
    getWorlds,
    getPaths,
    getEduBalance,
    getSignature,
    getAllWorlds,
    getPublicProfileSettings,
    setPublicProfileSettings,
    validateAnswerRequest,
    uploadFile,
    removeFile,
    getSignedDownloadUrl,
    saveAnswer,
    getAnswerToCheck,
    postAnswerFeedback,
    modifyPath,
    modifyWorld,
    getWaitingAnswerCount,
    markAnswerAsAdminRequired,
    getPrice,
    buyItem,
    useItem,
  };
}

export default useSls;

export type GetPathsPathLevelModel = PathLevel & {
  statusForUser: 'active' | 'done' | 'available' | 'unavailable';
};
export type GetPathsPathModel = Path & {
  statusForUser: 'active' | 'available' | 'unavailable';
  pathProgress: number;
  pathLevels: GetPathsPathLevelModel[];
  badges: {
    id: string;
    url: string;
  }[];
};

export enum UploadFileType {
  ANSWER = 'answer',
}
