import { LazyExoticComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useUser } from '../../hooks/useUser';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../../pages/Settings/common/Loading/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, selectUserIsLoading } from '../../redux/selectors/userSelector';
import { AppDispatch } from '../../redux/store';
import { logOut } from '../../redux/slices/userSlice';
import { logoutUrl } from '../../constants/apiEndpoints';

type ComponentType = {
  Component: LazyExoticComponent<() => JSX.Element>;
};

const PrivateRoute = ({ Component }: ComponentType) => {
  const dispatch = useDispatch<AppDispatch>();
  const { logout } = useAuth0();
  const { isAuthenticated, isLoading } = useUser();
  const user = useSelector(selectUser);
  const fetchUserIsLoading = useSelector(selectUserIsLoading);

  if (isLoading || fetchUserIsLoading) {
    return <Loading />;
  }

  if (isAuthenticated && !user) {
    dispatch(logOut());
    logout({
      returnTo: logoutUrl + '?error=lp',
    });
  }

  return isAuthenticated && user ? <Component /> : <Navigate to={routes.login} />;
};

export default PrivateRoute;
