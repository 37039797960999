import { useSelector } from 'react-redux';
import { selectUser } from '../redux/selectors/userSelector';
import { useAuth0 } from '@auth0/auth0-react';

export function useUser() {
  const user = useSelector(selectUser);
  const { isAuthenticated, isLoading } = useAuth0();

  return { user, isAuthenticated, isLoading };
}
