import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/selectors/userSelector';
import { BasicModal } from './Modal';
import * as S from './EarnReferralModal.styled';
import { t, Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { routes } from '../../constants/routes';

export type TSupportedMissingData = 'name' | 'ethWallet' | 'discordId';

const missingDataToTranslationKey: Record<TSupportedMissingData, () => string> = {
  name: () => t({ message: 'name_genitive' }),
  ethWallet: () => t({ message: 'ethWallet_genitive' }),
  discordId: () => t({ message: 'discordId_genitive' }),
};

type OnboardingModalT = {
  isShown: boolean;
  onCancel: () => void;
  missingData: TSupportedMissingData[];
};
export const OnboardingModal = ({ isShown, onCancel, missingData }: OnboardingModalT) => {
  const user = useSelector(selectUser);

  if (!user) {
    return <></>;
  }

  return (
    <BasicModal
      closeIcon={<></>}
      size="large"
      open={isShown}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}
    >
      <div className="">
        <div className="row text-center">
          <S.StyledH4Bold>
            <Trans>Hey YOU! We need your help!</Trans>
          </S.StyledH4Bold>
        </div>
        <div className="row text-center">
          <S.StyledBodySmall>
            <Trans>In your profile we are missing your</Trans>{' '}
            {missingData.map((data) => missingDataToTranslationKey[data]()).join(', ')}
          </S.StyledBodySmall>
        </div>
        <div className="row text-center">
          <S.StyledBodySmall>
            <Trans>Please, go</Trans>{' '}
            <Link to={routes.portal.settings.userDetails}>{t({ message: 'here' })}</Link>{' '}
            <Trans>and fill it</Trans>
          </S.StyledBodySmall>
        </div>
      </div>
    </BasicModal>
  );
};
